.custom-radio-btn input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.custom-radio-btn.inline div {
    display: inline;
}

.custom-radio-btn label {
    margin-top: 1px;
    margin-right: 10px;
    border-radius: 4px;
    min-width: 130px;
    font-weight: 600;
    text-align: center;
    padding: 6px 15px;
    cursor: pointer;
    font-size: 12px;
    width: 100%;
    max-width: 100%;

    color: #8c9cad;
    background-color: #d0d0d008;
    border: 1px solid #D0D0D0;

    text-transform: none;
    transition: all 0.15s ease;
    will-change: transform;
}

.custom-radio-btn label:hover {
    color: #fff;
    background-color: #11cdef;
    border-color: #11cdef;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.custom-radio-btn.inline label {
    width: auto !important;
    max-width: 100%;
}

.radio-w-50-p label {
    width: auto !important;
    min-width: 45%;
}

.custom-radio-btn input[type="radio"]:checked+label {
    background-color: #11c2ef;
    color: #fff;
}