/* Customize the label (the container) */
.custom-checkbox-container .checkbox-div {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.custom-checkbox-container.inline .checkbox-div {
    display: inline;
    padding-right: 10px;
}

/* Hide the browser's default checkbox */
.custom-checkbox-container .checkbox-div input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 20px;
    width: 20px;
    border: 1px solid #c7c1c1;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container .checkbox-div:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container .checkbox-div input:checked~.checkmark {
    background-color: #11c2ef;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-container .checkbox-div input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-container .checkbox-div .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}